import { ReactNode, useCallback } from 'react'
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { GOOGLE_RECAPTCHAV3_SITE_KEY, IS_DEVELOPMENT } from 'configs/index'

const reCaptchaKey = GOOGLE_RECAPTCHAV3_SITE_KEY as string
const GCaptchaProvider = ({ children }: { children: ReactNode }) => {
  return <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>{children}</GoogleReCaptchaProvider>
}

export const useGCaptchaVerify = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const handleReCaptchaVerify = useCallback(
    async (action?: string) => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available')
        return
      }

      const token = await executeRecaptcha(action)
      // Do whatever you want with the token
      return token
    },
    [executeRecaptcha],
  )

  const isCaptchaReady = IS_DEVELOPMENT || Boolean(executeRecaptcha) // For development, we don't need to verify the captcha
  return { isCaptchaReady, handleReCaptchaVerify }
}

export default GCaptchaProvider
