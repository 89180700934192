import { apiClient } from 'utils/apiClient'
import { withLogger } from 'utils/Logger'
import { UserBase, UserRole, UserVerification } from 'shared/types/V3/User'
import { NewBackEndApiResponseData } from 'shared/types/ApiResponseData'

async function checkExistedUser(email: string, role?: UserRole) {
  const response = await apiClient.get<NewBackEndApiResponseData<{ user: UserBase & UserVerification }>>(
    `/v2/onboarding/users/existed?email=${email}${role ? `&role=${role}` : ''}`,
  )

  const { data } = response.data

  return data?.user
}

export default withLogger(checkExistedUser, {
  action: 'check existed user',
  customErrorText: 'Cannot check existed user',
})
