import { getRedirectResult, AuthError } from 'firebase/auth'
import { firebaseAuth } from 'lib/firebase'
import handleExistingAccount from 'views/authentication/services/handleExistingAccount'

const authenticateWithRedirectResult = async () => {
  try {
    const userCredential = await getRedirectResult(firebaseAuth)
    return userCredential
  } catch (error) {
    const userCredential = await handleExistingAccount(error as unknown as AuthError, true)
    return userCredential
  }
}

export default authenticateWithRedirectResult
