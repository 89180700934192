import ModalWrapper from 'shared/components/modal/ModalWrapper'
import classNames from 'classnames'

type ShowTasteMixerModalProps = {
  onReEdit: () => void
  onProceedWithoutReferralCode: () => void // this is needed when showInvalidMessageOnModal is true
}

const WrongReferralCodeModal = (props: ShowTasteMixerModalProps) => {
  const { onProceedWithoutReferralCode, onReEdit } = props
  return (
    <>
      <ModalWrapper disableBackdropClick open blurBackdrop>
        <div className="w-[305px] max-w-full rounded-[24px] bg-white p-[20px] lg:w-[500px]">
          <div className="flex w-full flex-col gap-[20px] text-[14px]">
            <h2 className="font-semibold uppercase text-kokushoku-black/30">Referral code invalid!</h2>
            <p>The referral code you entered is invalid. Would you like to edit or proceed without a referral code?</p>

            {/* edit code and proceed without referral code buttons */}
            <div className={classNames('flex w-full flex-col items-center text-[12px] font-semibold')}>
              <button
                type="button"
                onClick={onReEdit}
                className="inline-flex w-full items-center justify-center rounded-full border border-black/10 bg-mint-green px-[16px] py-[12px] text-[14px] font-semibold leading-[140%] disabled:opacity-40"
              >
                Edit code
              </button>
              <button
                type="button"
                onClick={onProceedWithoutReferralCode}
                className="px-[16px] py-[12px] text-[14px] font-semibold leading-[140%] disabled:opacity-40"
              >
                Proceed without referral code
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default WrongReferralCodeModal
